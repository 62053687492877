*{
  padding: 0%;
  margin: 0%;
  font-family: 'DM Sans', sans-serif;
}
.Login{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.Login-Box{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.alert{
  width: 200px;
  height: 100px;
  background-color: aquamarine;
  border-radius: 10px 0px 0px 10px;
}

.scrollHide{
  
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.scrollHide::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.sideNavHover:hover{
  color: #FEA800 !important;
}